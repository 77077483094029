<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <div>
        <h1>Horses</h1>
        <p>
          Four horses live on our farm. They are of different and mixed breed.
        </p>

        <h2>Blind mare</h2>
        <picture>
          <source media="(max-width: 500px)" srcset="../../assets/images/sm-sacageweaBlind.jpg">
          <img src="../../assets/images/sacageweaBlind.jpg">
        </picture>
        <p>
          One of the horses, Sacagewea, went blind for an unknown reason and we keep her in a controlled environment
          since then. For one reason the terrain where the horses spend most of their time is not flat and even and we
          don't want Sacagewa to break a leg. Breaking a leg to a horse would be a huge problem. For another reason we
          keep her protected from any conflict over ranking as she used to be the lead mare of the small herd.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/views/concept/Sidebar.vue';

export default {
  name: "HorsesEN",
  components: {
    Sidebar,
  },

};
</script>
